import { DateISOString } from './misc';
import {
  BasicExperienceObject,
  MinimalExperienceObject,
  ProjectExperienceData,
} from './ExperienceObject';
import { ConnectionObjectV2 } from './ConnectionObject';
import { RecommendationObject } from './RecommendationObject';

export type NotificationId = string;

export enum NotificationType {
  ConnectionRequest = 'ConnectionRequest',
  ConnectionApproved = 'ConnectionApproved',
  NewSharedExperiences = 'NewSharedExperiences',
  CompanyLikedYou = 'CompanyLikedYou',
  CollaboratorRequest = 'CollaboratorRequest',
  ApplicationStatus = 'ApplicationStatus',
  TeamUpRequest = 'TeamUpRequest',
  TeamUpRequestAccepted = 'TeamUpRequestAccepted',
  CompanyWantsToInterviewYou = 'CompanyWantsToInterviewYou',
  InterviewScheduled = 'InterviewScheduled',
  EvaluationInterviewScheduled = 'EvaluationInterviewScheduled',
}

export interface BasicNotificationObject {
  nid: NotificationId;
  type: NotificationType;
  unread: boolean;
  deleted?: boolean;
  createdAt: DateISOString;
}

export interface ConnectionRequestNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.ConnectionRequest;
  connection: ConnectionObjectV2;
  sharedExperiences: MinimalExperienceObject[];
}

export interface ConnectionApprovedNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.ConnectionApproved;
  connection: ConnectionObjectV2;
  sharedExperiences: MinimalExperienceObject[];
}

export interface NewSharedExperiencesNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.NewSharedExperiences;
  sharedExperiences: MinimalExperienceObject[];
}

export interface CompanyLikedYouNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.CompanyLikedYou;
  signalId: string;
  justPublished?: boolean;
}

export interface CompanyWantsToInterviewYouNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.CompanyWantsToInterviewYou;
  ciid: string;
  companyName: string;
  companyImageUrl?: string;
  calComBookingId?: number;
}

export interface InterviewScheduledNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.InterviewScheduled;
  ciid: string;
  companyName: string;
  companyImageUrl?: string;
  interviewStartDate: string;
  interviewEndDate: string;
  interviewUrl: string;
  builderTimezone: string;
}

export interface EvaluationInterviewScheduledNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.EvaluationInterviewScheduled;
  preVettingFormNonce: string;
  calComBookingUrl?: string;
  calComBookingId: number;
  calComBookingUid: string;
  interviewStartDate: string;
  builderTimezone: string;
}

export interface ApplicationStatusNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.ApplicationStatus;
  title: string;
  description: string;
  color: string;
  status: string;
  companyImageUrl?: string;
}

export interface TeamUpRequestNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.TeamUpRequest;
  userRecommendation: RecommendationObject;
}

export interface TeamUpRequestAcceptedNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.TeamUpRequestAccepted;
  userRecommendation: RecommendationObject;
}

export interface CollaboratorRequestNotificationObject
  extends BasicNotificationObject {
  type: NotificationType.CollaboratorRequest;
  experience: BasicExperienceObject & ProjectExperienceData;
}

export type NotificationObject =
  | ConnectionRequestNotificationObject
  | ConnectionApprovedNotificationObject
  | NewSharedExperiencesNotificationObject
  | CompanyLikedYouNotificationObject
  | CollaboratorRequestNotificationObject
  | ApplicationStatusNotificationObject
  | TeamUpRequestNotificationObject
  | TeamUpRequestAcceptedNotificationObject
  | CompanyWantsToInterviewYouNotificationObject
  | InterviewScheduledNotificationObject
  | EvaluationInterviewScheduledNotificationObject;

export type UnreadNotificationObject = NotificationObject & { unread: boolean };
